<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <h3 class="text-h5 mb-2">Страны</h3>
      <v-btn
          v-if="can($permissions['COUNTRIES.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="max-width: 50px" class="text-left">
              №
            </th>
            <th>Keyword</th>
            <th>Value Ru</th>
            <th>Value Uz</th>
            <th>Value UzL</th>
            <th>Value En</th>
            <th v-if="ableToAction" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in list"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.keyword }}</td>
            <td>{{ item.valueRu }}</td>
            <td>{{ item.valueUz }}</td>
            <td>{{ item.valueUzl }}</td>
            <td>{{ item.valueEn }}</td>
            <td v-if="ableToAction">
              <v-row justify="center">

                <div style="min-width: 60px" class="d-flex justify-center mr-2">
                  <v-icon v-if="can($permissions['COUNTRIES.MOVEUP'])" @click="moveUp(item.id)"
                          class="cursor-pointer move-icon-up">mdi-chevron-up
                  </v-icon>
                  <v-icon v-if="can($permissions['COUNTRIES.MOVEDOWN'])" @click="moveDown(item.id)"
                          class="cursor-pointer move-icon-down">mdi-chevron-down
                  </v-icon>
                </div>
                <v-icon
                    v-if="can($permissions['COUNTRIES.UPSERT'])"
                    @click="getItem(item.id)"
                    class="cursor-pointer mr-2">mdi-pencil
                </v-icon>
                <v-icon
                    v-if="can($permissions['COUNTRIES.TOGGLE'])"
                    @click="toggle(item.id)"
                    class="cursor-pointer">
                  {{ item.isDeleted ? 'mdi-toggle-switch-off-outline' : 'mdi-toggle-switch-outline' }}
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span v-if="update" class="headline">Изменить</span>
          <span v-else class="headline">Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Keyword"
                    outlined
                    hide-details="auto"
                    v-model="item.keyword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value Ru"
                    v-model="item.valueRu"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value Uz"
                    v-model="item.valueUz"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value UzL"
                    v-model="item.valueUzl"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    outlined
                    hide-details="auto"
                    label="Value En"
                    v-model="item.valueEn"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveItem"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      item: {
        id: 0,
        keyword: '',
        valueRu: '',
        valueEn: '',
        valueUz: '',
        valueUzl: ''
      },
      dialog: false,
      update: false
    }
  },
  computed: {
    ableToAction() {
      return this.can(this.$permissions['COUNTRIES.UPSERT']) ||
          this.can(this.$permissions['COUNTRIES.TOGGLE']) ||
          this.can(this.$permissions['COUNTRIES.MOVEUP']) ||
          this.can(this.$permissions['COUNTRIES.MOVEDOWN'])
    }
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-region/getCountries');
        this.list = res.result;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getItem(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`/api-region/getCountry?countryId=${id}`);
        this.item = res.result;
        this.dialog = true;
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async saveItem() {
      try {
        const res = await this.$http.post('/api-region/upsertCountry', this.item);
        this.dialog = false;
        this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async toggle(id) {
      try {
        const res = await this.$http.get(`/api-region/toggleCountry?countryId=${id}`);
        this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async moveUp(id) {
      try {
        const res = await this.$http.get(`/api-region/moveCountryUp?countryId=${id}`);
        this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async moveDown(id) {
      try {
        const res = await this.$http.get(`/api-region/moveCountryDown?countryId=${id}`);
        this.list = res.result;
        this.successNotification();
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.item = {
        id: 0,
        keyword: '',
        valueRu: '',
        valueEn: '',
        valueUz: '',
        valueUzl: ''
      }
    }
  },
  watch: {},
  created() {
    this.getList();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>